/*
 ** EditView arolla define fields for editview reducer
 */
import { BSHOrder, Order, Project, Layer, RecipeLayer, Recipe, ProjectPlate, IncomingPackage, PlanerSetup } from '../../../utils/models';

const arolla_config = {
  orderFields: [
    [
      [{ ...Order.fields.orderPicture }],
      [
        { ...Order.fields.projectNumber },
        { ...Order.fields.projectName },
        { ...Order.fields.panelsNumber },
        { ...Order.fields.layerNumber },
        { ...Order.fields.isBeam },
        { ...Order.fields.largeFingerJoint },
        { ...Order.fields.panelLength },
        { ...Order.fields.panelWidth },
        { ...Order.fields.panelThickness }
      ]
    ],
    // ]
    [[{ ...Order.fields.panelPicture }]]
  ],

  recipeOrderFields: [
    [
      [{ ...Recipe.fields.orderPicture }],
      [
        { ...Recipe.fields.identifier },
        { ...Order.fields.panelThickness },
        { ...Order.fields.oversize, halfsize: true, editable: true },
        { ...Order.fields.lenOversizeL, halfsize: true, editable: true },
        { ...Order.fields.oversizeWidth, halfsize: true },
        { ...Order.fields.lenOversizeC, halfsize: true }
      ]
    ]
  ],

  incomingPackageFields: [
    [
      [{ ...IncomingPackage.fields.identifier }, { ...IncomingPackage.fields.lamellaLength }, { ...IncomingPackage.fields.thickness }, { ...IncomingPackage.fields.width }],
      [{ ...IncomingPackage.fields.piecesPerLayer }, { ...IncomingPackage.fields.numberOfLayers }]
    ]
  ],

  bshorderFields: [
    /* [
      { ...BSHOrder.fields.orderPicture }
    ], */
    [
      // { ...BSHOrder.fields.quality },
      { ...BSHOrder.fields.client },
      { ...BSHOrder.fields.lamellaLength },
      { ...BSHOrder.fields.lamellaWidth },
      { ...BSHOrder.fields.lamellaThickness },
      { ...BSHOrder.fields.rawThickness },
      { ...BSHOrder.fields.rawWidth },
      { ...BSHOrder.fields.stackingNumOfLayers },
      { ...BSHOrder.fields.stackingLamellasPerLayer },
      { ...BSHOrder.fields.numOfLamellas }
    ]
  ],

  recipeBSHOrderFields: [
    [{ ...BSHOrder.fields.orderPicture }],
    [
      { ...Recipe.fields.identifier },
      { ...BSHOrder.fields.lamellaThickness },
      { ...BSHOrder.fields.oversize, halfsize: true, editable: true },
      { ...BSHOrder.fields.lenOversizeL, halfsize: true, editable: true },
      { ...BSHOrder.fields.oversizeWidth, halfsize: true },
      { ...BSHOrder.fields.lenOversizeC, halfsize: true }
    ]
  ],

  projectFields: [[[{ ...Project.fields.panelLength }]]],

  projectOrderFields: [
    [
      // { ...Order.fields.quality },
      { ...Order.fields.orderNumber },
      { ...Order.fields.panelLength },
      { ...Order.fields.panelWidth },
      { ...Order.fields.panelsNumber },
      { ...Order.fields.recipeSelect }
    ]
  ],

  plateFields: [
    [
      { ...ProjectPlate.fields.quality, editable: true },
      { ...ProjectPlate.fields.woodType, editable: true },
      { ...ProjectPlate.fields.orientation, halfsize: true },
      { ...ProjectPlate.fields.rotation, halfsize: true },
      { ...ProjectPlate.fields.lamellaPlannedWidth },
      { ...ProjectPlate.fields.lamellaPlannedThickness },
      { ...ProjectPlate.fields.platesOnLine, editable: true },
      { ...ProjectPlate.fields.platesInStorage, editable: true },
      { ...ProjectPlate.fields.totalPlates, editable: true },
      { ...ProjectPlate.fields.projectNumber },
      { ...ProjectPlate.fields.layerNo }
    ]
  ],

  recipeProjectFields: [
    [{ ...Project.fields.orderPicture }],
    [
      { ...Recipe.fields.identifier },
      { ...Project.fields.panelThickness },
      { ...Project.fields.oversize, halfsize: true, editable: true },
      { ...Project.fields.lenOversizeL, halfsize: true, editable: true },
      { ...Project.fields.oversizeWidth, halfsize: true },
      { ...Project.fields.lenOversizeC, halfsize: true }
    ]
  ],

  layerFields: [
    [
      [
        { ...Layer.fields.layerNo, width: 1.2, halfsize: true },
        //{ ...Layer.fields.quality, width: 1.2, halfsize: true },
        { ...Layer.fields.orientation, width: 1.2, halfsize: true },
        { ...Layer.fields.rotation, width: 1.2, halfsize: true }
      ],
      [
        { ...Layer.fields.lamellaThickness, width: 2, halfsize: true },
        { ...Layer.fields.rawThickness, width: 2, halfsize: true },
        { ...Layer.fields.rawWidth, width: 2, halfsize: true },
        { ...Layer.fields.lamellaWidth, width: 2, halfsize: true },
        { ...Layer.fields.lamellaLength, width: 2, halfsize: true },
        { ...Layer.fields.isOuter, width: 2 }
      ]
    ],
    [[{ ...Layer.fields.layerPicture }]]
  ],

  recipeLayerFields: [
    [
      [
        { ...RecipeLayer.fields.layerNo, width: 1.2 },
        { ...RecipeLayer.fields.woodType, width: 1.2 },
        { ...RecipeLayer.fields.orientation, width: 1.2, halfsize: true },
        { ...RecipeLayer.fields.rotation, width: 1.2, halfsize: true },
        { ...RecipeLayer.fields.quality, width: 1.2 }
      ],
      [
        { ...RecipeLayer.fields.lamellaPlannedThickness, width: 2 },
        { ...RecipeLayer.fields.lamellaThickness, width: 2 },
        { ...RecipeLayer.fields.lamellaPlannedWidth, width: 2 },
        { ...RecipeLayer.fields.minTakeAway, width: 2 }
      ],
      [
        { ...RecipeLayer.fields.hasGlue, width: 1.2 },
        { ...RecipeLayer.fields.whiteGlue, width: 1.2 }
      ]
    ]
  ],

  projectWithRecipeFields: [[[{ ...Order.fields.projectSelect }], [{ ...Project.fields.panelLength }]]],

  kvhLayerFields: [
    [
      { ...Layer.fields.layerNo, width: 2, halfsize: true },
      { ...Layer.fields.separatorDistance, width: 2, halfsize: true },
      { ...Layer.fields.orientation, width: 1.2, halfsize: true },
      { ...Layer.fields.rotation, width: 1.2, halfsize: true },
      { ...Layer.fields.quality, width: 2, halfsize: true },
      { ...Layer.fields.woodType, width: 2, halfsize: true },
      { ...Layer.fields.batchId, width: 1.2 },
      { ...Layer.fields.primingTop, width: 2, quartersize: true },
      { ...Layer.fields.primingBottom, width: 2, quartersize: true },
      { ...Layer.fields.chamferTop, width: 2, quartersize: true },
      { ...Layer.fields.chamferBottom, width: 2, quartersize: true }
    ],
    [
      { ...Layer.fields.lamellaThickness, width: 2, halfsize: true },
      { ...Layer.fields.lamellaPlannedThickness, width: 2, halfsize: true },
      { ...Layer.fields.lamellaWidth, width: 2, halfsize: true },
      { ...Layer.fields.lamellaPlannedWidth, width: 2, halfsize: true },
      { ...Layer.fields.numKVHLayers, width: 2, halfsize: true },
      { ...Layer.fields.numLamellasPerLayer, width: 2, halfsize: true },
      { ...Layer.fields.lamellaLength, width: 2, halfsize: true },
      { ...Layer.fields.numOfLamellas, width: 2, halfsize: true }
    ]
  ],

  planerSetupFields: [
    [
      [
        { ...PlanerSetup.fields.identifier },
        { ...PlanerSetup.fields.s2Thickness },
        { ...PlanerSetup.fields.s4Width },
        { ...PlanerSetup.fields.s5Fold },
        { ...PlanerSetup.fields.s5Depth },
        { ...PlanerSetup.fields.s6Fold },
        { ...PlanerSetup.fields.s6Depth }
      ],
      [{ ...PlanerSetup.fields.s7Enable }, { ...PlanerSetup.fields.s8Enable }, { ...PlanerSetup.fields.chamferTop }, { ...PlanerSetup.fields.chamferBottom }, { ...PlanerSetup.fields.turn }]
    ]
  ]
};

export default arolla_config;
