/*
 ** EditView sodra define fields for editview reducer
 */
import { BSHOrder, Order, Layer, RecipeLayer, Recipe, IncomingPackage, PlanerSetup, BSHLayer } from '../../../utils/models';
import { orderChildCalculateValues, arborealMaterialCodeCalculator, schilligerChildCalculateValues, schneiderModelCalculator } from '../../../utils/modelFunctions/calculators';
import { generalValidator, planerSetupValidator } from '../../../utils/modelFunctions/validators';
import FormattedMessage from '../../../components/TranslationWrapper';
import modelFieldMessages from '../../../utils/modelFieldMessages';

const schilliger_config = {
  orderFields: [
    [
      [{ ...Order.fields.projectNumber }, { ...Order.fields.projectName }, { ...Order.fields.masterpanel }, { ...Order.fields.nestingPanelNumber }, { ...Order.fields.panelMaterial }],
      [{ ...Order.fields.panelLength }, { ...Order.fields.panelWidth }, { ...Order.fields.panelThickness }]
    ],

    [[{ ...Order.fields.orderPicture }], [{ ...Order.fields.panelPicture }]]
  ],

  recipeOrderFields: [
    [
      [{ ...Recipe.fields.orderPicture }],
      [
        { ...Recipe.fields.identifier },
        { ...Order.fields.panelThickness },
        { ...Order.fields.oversize, halfsize: true, editable: true },
        { ...Order.fields.lenOversizeL, halfsize: true, editable: true },
        { ...Order.fields.oversizeWidth, halfsize: true },
        { ...Order.fields.lenOversizeC, halfsize: true }
      ]
    ]
  ],

  incomingPackageFields: [
    [
      [{ ...IncomingPackage.fields.identifier }, { ...IncomingPackage.fields.quality }, { ...IncomingPackage.fields.woodType }, { ...IncomingPackage.fields.packageNumber }],
      [
        { ...IncomingPackage.fields.piecesPerLayer },
        { ...IncomingPackage.fields.numberOfLayers },
        { ...IncomingPackage.fields.thickness },
        { ...IncomingPackage.fields.width },
        { ...IncomingPackage.fields.lamellaLength }
      ]
    ]
  ],
  bshorderFields: [
    [
      [
        { ...BSHOrder.fields.orderNumber },
        { ...BSHOrder.fields.stackingName },
        { ...BSHOrder.fields.lamellaWidth },
        { ...BSHOrder.fields.lamellaThickness },
        { ...BSHOrder.fields.rawThickness },
        { ...BSHOrder.fields.rawWidth },
        { ...BSHOrder.fields.stackingNumOfLayers },
        { ...BSHOrder.fields.stackingLamellasPerLayer }
      ]
    ]
  ],
  bshLayerFields: [
    [
      [
        { ...Layer.fields.layerNo, width: 1.2, editable: false }, // positionInStack
        { ...BSHLayer.fields.layerId, width: 1.2, halfsize: true },
        { ...Layer.fields.erpId, width: 1.2 },
        { ...Layer.fields.batchId, width: 1.2, editable: true },
        { ...Layer.fields.quality, width: 1.2 },
        { ...BSHLayer.fields.numOfPackages, width: 1.2 }
      ],
      [
        { ...Layer.fields.lamellaThickness, width: 2, halfsize: true },
        { ...Layer.fields.lamellaPlannedThickness, width: 2, halfsize: true },
        { ...Layer.fields.lamellaWidth, width: 2, halfsize: true, editable: true },
        { ...Layer.fields.lamellaPlannedWidth, width: 2, halfsize: true },
        { ...Layer.fields.lamellaLength_schneider, width: 2 },
        { ...Layer.fields.numOfLamellas, width: 2, editable: true },
        { ...BSHLayer.fields.numOfPackages },
        { ...BSHLayer.fields.stackingNumOfLayers },
        { ...BSHLayer.fields.stackingLamellasPerLayer }
      ]
    ]
    // [
    //   [{ ...BSHLayer.fields.bottomTension }, { ...BSHLayer.fields.bottomN1D }, { ...BSHLayer.fields.coreN2M }, { ...BSHLayer.fields.topN1D }, { ...BSHLayer.fields.topTension }],
    //   [{ ...BSHLayer.fields.beamWidth, width: 2, editable: true }]
    // ],

    // Defining group fileds Example
    // {
    //   groupMode: true,
    //   groupName: <FormattedMessage {...modelFieldMessages.beamStructure} identifier={'beamStructure'} />,
    //   fields: [[{ ...BSHLayer.fields.bottomTension }, { ...BSHLayer.fields.bottomN1D }, { ...BSHLayer.fields.coreN2M }, { ...BSHLayer.fields.topN1D }, { ...BSHLayer.fields.topTension }]]
    // },
    // [{ ...BSHLayer.fields.beamPicture, halfsize: false }]
  ],

  layerFields: [
    [
      [
        { ...Layer.fields.layerNo, width: 1.2, halfsize: true },
        { ...Layer.fields.quality, width: 1.2, halfsize: true },
        { ...Layer.fields.strengthString, width: 1.2, halfsize: true },
        { ...Layer.fields.woodType, width: 1.2, halfsize: true },
        // { ...Layer.fields.treatment, width: 1.2, halfsize: true },
        { ...Layer.fields.material, width: 1.2, halfsize: true },
        { ...Layer.fields.orientation, width: 1.2, halfsize: true },
        { ...Layer.fields.layerIdentifier, width: 1.2, halfsize: true }
      ],
      [
        { ...Layer.fields.lamellaThickness, width: 2, halfsize: true },
        { ...Layer.fields.lamellaPlannedThickness, width: 2, halfsize: true },
        { ...Layer.fields.lamellaWidthNoLimits, width: 2, halfsize: true },

        { ...Layer.fields.lamellaPlannedWidth, width: 2, halfsize: true },

        { ...Layer.fields.numOfLamellasEditable, width: 2 },
        { ...Layer.fields.numOfLamellasWood, width: 2 },
        { ...Layer.fields.numOfLamellasIns, width: 2 },
        { ...Layer.fields.fixedRecipeLayer, width: 1, halfsize: true },
        { ...Layer.fields.lengthoffsetWidth, width: 1, halfsize: true }
      ]
    ],

    [[{ ...Layer.fields.widthoffsetData }, { ...Layer.fields.notLamellaData }, { ...Layer.fields.lengthoffsetData }]],
    [[{ ...Layer.fields.layerPicture }]]
  ],

  recipeLayerFields: [
    [
      [
        // { ...Layer.fields.recipeLayerSelect },
        { ...RecipeLayer.fields.layerNo, width: 1.2 },
        { ...RecipeLayer.fields.woodType, width: 1.2 },
        { ...Layer.fields.orientation, width: 1.2, halfsize: true },
        { ...RecipeLayer.fields.rotation, width: 1.2, halfsize: true },
        { ...RecipeLayer.fields.quality, width: 1.2 }
      ],
      [
        { ...RecipeLayer.fields.lamellaPlannedThickness, width: 2 },
        { ...RecipeLayer.fields.lamellaThickness, width: 2 },
        { ...RecipeLayer.fields.lamellaPlannedWidth, width: 2 },
        { ...RecipeLayer.fields.minTakeAway, width: 2 }
      ],
      [
        { ...RecipeLayer.fields.hasGlue, width: 1.2 },
        { ...RecipeLayer.fields.whiteGlue, width: 1.2 }
      ]
    ],
    [[{ ...Layer.fields.recipewidthoffsetData }, { ...Layer.fields.recipenotLamellaData }, { ...Layer.fields.recipelengthoffsetData }]]
  ],

  orderWithRecipeFields: [
    [
      [{ ...Order.fields.recipeSelect }, { ...Recipe.fields.getOrderWithRecipeImage }],
      [{ ...Recipe.fields.identifier }, { ...Order.fields.orderNumber }, { ...Order.fields.panelWidth }, { ...Order.fields.panelLength }, { ...Order.fields.panelThickness }]
    ]
  ],

  orderLayerRecipeFields: [
    [
      [{ ...Recipe.fields.getOrderWithRecipeImage }],
      [{ ...Recipe.fields.identifier }, { ...Order.fields.orderNumber }, { ...Order.fields.panelWidth }, { ...Order.fields.panelLength }, { ...Order.fields.panelThickness }]
    ]
  ],

  layerWithRecipeFields: [[[{ ...Layer.fields.recipeLayerSelect }, { ...Layer.fields.getLayerWithRecipeImage }]]],

  planerSetupFields: [
    [
      [
        { ...PlanerSetup.fields.rawWidth },
        { ...PlanerSetup.fields.rawThickness },
        { ...PlanerSetup.fields.lamellaPlannedThickness },
        { ...PlanerSetup.fields.W1Takeaway },
        { ...PlanerSetup.fields.W3Takeaway },
        { ...PlanerSetup.fields.quality },
        { ...PlanerSetup.fields.posWhiteGlue },
        { ...PlanerSetup.fields.posHotmelt },
        { ...PlanerSetup.fields.linesOfWhiteGlue },
        { ...PlanerSetup.fields.linesOfHotmelt }
      ],
      [{ ...PlanerSetup.fields.splitWood }, { ...PlanerSetup.fields.turningZinc }, { ...PlanerSetup.fields.turningAfterPress }],
      [{ ...PlanerSetup.fields.turningBeforePress }, { ...PlanerSetup.fields.turningEverySecond }, { ...PlanerSetup.fields.turningEveryFirst }]
    ]
  ],
  /* IF project needs custom validator or calculator for models */
  calculatorsConfig: {
    order: {
      childCalculator: schilligerChildCalculateValues
    }
  }
};

export default schilliger_config;
