import modelFieldMessages from './modelFieldMessages';
import { PriorityHigh } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import { FormControl, InputLabel, Select, MenuItem, Checkbox, Typography, Box, FormHelperText } from '@mui/material';
import { useDispatch } from 'react-redux';
import { sendToWs } from '../containers/Websocket/actions';
import globalMessages from '../globalMessages';

class JoinedDimensions {
  static orientationChoices = {
    3: modelFieldMessages.KVHPackageType,
    2: modelFieldMessages.qPackageType,
    1: modelFieldMessages.lPackageType
  };

  static rotationChoices = {
    2: modelFieldMessages.altRotation,
    1: modelFieldMessages.upRotation,
    0: modelFieldMessages.downRotation
  };

  static fields = {
    orderId: {
      key: 'paNr',
      type: 'string',
      title: modelFieldMessages.paNr
    },
    orderNumber: {
      key: 'orderNumber',
      type: 'string',
      title: modelFieldMessages.orderNumber
    },
    jobNumber: {
      key: 'jobNumber',
      type: 'string',
      title: modelFieldMessages.jobNumber
    },
    inputArticle: {
      key: 'inputArticle',
      type: 'string',
      title: modelFieldMessages.inputArticle
    },
    chargeNumber: {
      key: 'chargeNumber',
      type: 'string',
      title: modelFieldMessages.chargeNumber
    },
    orderImportNumber: {
      key: 'orderImportNumber',
      type: 'string',
      title: modelFieldMessages.orderImportNumber
    },
    fragment: {
      key: 'fragmentId',
      type: 'string',
      title: modelFieldMessages.fragment
    },
    fragmentOpen: {
      key: 'fragmentOpen',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.fragmentOpen
    },
    orderColor: {
      key: 'orderColor',
      type: 'string',
      format: {
        type: 'color'
      },
      title: modelFieldMessages.order
    },
    layerStack: {
      key: 'totalLayers',
      type: 'string',
      identifier: 'layerMatrix',
      color: 'green',
      format: {
        type: 'productStack'
      }
    },
    layerDoneStack: {
      key: 'totalLayers',
      type: 'string',
      identifier: 'layerDoneMatrix',
      color: 'blue',
      format: {
        type: 'productStack'
      }
    },
    progress: {
      key: 'progress',
      type: 'string',
      format: {
        type: 'progress'
      }
    },
    orderCharge: {
      key: 'orderCharge',
      type: 'string',
      title: modelFieldMessages.orderCharge
    },
    salesOrderNumber: {
      key: 'salesOrderNumber',
      type: 'string',
      title: modelFieldMessages.salesOrderNumber
    },
    projectName_schilliger: {
      key: 'projectName',
      type: 'string',
      title: modelFieldMessages.projectName_schilliger
    },
    combinedOrderColor: {
      key: 'combinedColors',
      type: 'string',
      format: {
        type: 'combinedColor'
      }
      // title: modelFieldMessages.orderColor,
    },
    stackIndex: {
      key: 'stackIndex',
      type: 'string',
      title: modelFieldMessages.stackIndex
    },
    canBeDeleted: {
      key: 'group',
      title: modelFieldMessages.canBeDeleted,
      format: {
        type: 'object',
        str: '{canBeDeleted}',
        defaultStr: '',
        keys: ['canBeDeleted']
      }
    },
    woodTreatment: {
      key: 'woodTreatment',
      type: 'string',
      title: modelFieldMessages.woodTreatment
    },
    isActiveInClt: {
      key: 'isActiveInClt',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.isActiveInClt
    },
    canUseAnyPackage: {
      key: 'canUseAnyPackage',
      format: {
        type: 'boolean',
        dispatchToControl: true,
        dispatchMessage: 'toggle-block'
      },
      title: modelFieldMessages.allowSelect
    },
    stored: {
      key: 'stored',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.stored
    },
    plannedLamellas: {
      key: 'plannedLamellas',
      format: {
        type: 'string'
      },
      title: modelFieldMessages.plannedLamellas
    },
    cutLamellas: {
      key: 'cutLamellas',
      format: {
        type: 'string'
      },
      title: modelFieldMessages.cutLamellas
    },
    rawDimensions: {
      title: modelFieldMessages.rawDimensions,
      format: {
        type: 'object_from_many',
        str: '{lamellaThickness} x {lamellaWidth} x {lamellaLength}',
        keys: ['lamellaWidth', 'lamellaThickness', 'lamellaLength'],
        bold: false,
        size: '10px'
      }
    },
    rawThickness: {
      key: 'lamellaThickness',
      title: modelFieldMessages.rawThickness,
      format: {
        type: 'string'
      }
    },
    rawDimensionsConverted: {
      title: modelFieldMessages.rawDimensions,
      format: {
        type: 'conversion_from_many',
        str: '{lamellaThickness} x {lamellaWidth} x {lamellaLength} ',
        keys: [
          { key: 'lamellaWidth', fromUnit: 'millimeters', toUnit: 'inches' },
          { key: 'lamellaThickness', fromUnit: 'millimeters', toUnit: 'inches' },
          { key: 'lamellaLength', fromUnit: 'millimeters', toUnit: 'inches' }
        ],
        fromUnitLabel: 'mm',
        toUnitLabel: 'in',
        unitType: 'length'
      }
    },
    takeAwayLeftConverted: {
      title: modelFieldMessages.imperialW1Takeaway,
      format: {
        type: 'conversion_from_many',
        str: '{takeAwayLeft} ',
        keys: [{ key: 'takeAwayLeft', fromUnit: 'millimeters', toUnit: 'inches' }],
        fromUnitLabel: 'mm',
        toUnitLabel: 'in',
        unitType: 'length'
      }
    },
    takeAwayRightConverted: {
      title: modelFieldMessages.imperialW3Takeaway,
      format: {
        type: 'conversion_from_many',
        str: '{takeAwayRight} ',
        keys: [{ key: 'takeAwayRight', fromUnit: 'millimeters', toUnit: 'inches' }],
        fromUnitLabel: 'mm',
        toUnitLabel: 'in',
        unitType: 'length'
      }
    },
    rawDimensionsIns: {
      title: modelFieldMessages.insDimensions,
      format: {
        type: 'object_from_many',
        str: '{lamellaThickness} x {insulationWidth} x {insLamellaLength}',
        keys: ['lamellaThickness', 'insulationWidth', 'insLamellaLength'],
        bold: false,
        size: '13px'
      }
    },
    rawDimensionsMM2: {
      title: modelFieldMessages.rawDimensions,
      format: {
        type: 'object_from_many',
        str: '{lamellaThickness} x {lamellaWidth} x {lamellaLength}',
        keys: ['lamellaWidth', 'lamellaThickness', 'lamellaLength'],
        bold: false,
        size: '13px'
      }
    },
    carpetStartIcon: {
      format: {
        type: 'conditional_icon',
        condition: (item) => item.startOfCarpet == true,
        component: (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <FormattedMessage {...modelFieldMessages.newCarpet} />
            <PriorityHigh />
          </div>
        ),
        keys: ['startOffset'],
        bold: false,
        color: '#ff0f0f'
      }
    },
    group: {
      key: 'group',
      title: modelFieldMessages.group,
      format: {
        type: 'object',
        str: '{id}',
        defaultStr: '',
        keys: ['id']
      }
    },
    groupString: {
      key: 'groupString',
      type: 'string',
      title: modelFieldMessages.groupString
    },
    lamellasPerPress: {
      key: 'lamellasPerPress',
      type: 'string',
      title: modelFieldMessages.lamellasPerPress
    },
    noGroupLamellasNeeded: {
      key: 'noGroupLamellasNeeded',
      type: 'string',
      title: modelFieldMessages.noGroupLamellasNeeded
    },
    planedDimensions: {
      title: modelFieldMessages.planedDimensions,
      format: {
        type: 'object_from_many',
        str: '{lamellaPlannedThickness} x {lamellaPlannedWidth}',
        keys: ['lamellaPlannedWidth', 'lamellaPlannedThickness'],
        bold: false,
        size: '10px'
      }
    },
    planedDimensionsConverted: {
      title: modelFieldMessages.planedDimensions,
      format: {
        type: 'conversion_from_many',
        str: '{lamellaPlannedThickness} x {lamellaPlannedWidth} ',
        keys: [
          { key: 'lamellaPlannedWidth', fromUnit: 'millimeters', toUnit: 'inches' },
          { key: 'lamellaPlannedThickness', fromUnit: 'millimeters', toUnit: 'inches' }
        ],
        fromUnitLabel: 'mm',
        toUnitLabel: 'in',
        unitType: 'length'
      }
    },
    planedDimensionsMM2: {
      title: modelFieldMessages.planedDimensions,
      format: {
        type: 'object_from_many',
        str: '{lamellaPlannedThickness} x {lamellaPlannedWidth}',
        keys: ['lamellaPlannedWidth', 'lamellaPlannedThickness'],
        bold: false,
        size: '13px'
      }
    },
    planedDimensionsSchneider: {
      title: modelFieldMessages.planedDimensionsSchneider,
      format: {
        type: 'object_from_many',
        str: '{lamellaPlannedThickness} x {lamellaPlannedWidth}',
        keys: ['lamellaPlannedWidth', 'lamellaPlannedThickness'],
        bold: false,
        size: '13px'
      }
    },
    rotation: {
      key: 'rotation',
      type: 'choices',
      title: modelFieldMessages.rotation,
      format: {
        type: 'discreteChoices',
        choices: JoinedDimensions.rotationChoices
      }
    },
    lamellaLengthQ: {
      key: 'lamellaLengthQ1',
      type: 'string',
      title: modelFieldMessages.lengthLamellaCross
    },
    materialCode: {
      key: 'materialCode',
      type: 'string',
      title: modelFieldMessages.materialCode
    },
    emptied: {
      key: 'emptied',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.emptied
    },
    lamellasDrying: {
      key: 'lamellasDrying',
      type: 'string',
      title: modelFieldMessages.lamellasDrying
    },
    batchId: {
      key: 'batchId',
      type: 'string',
      title: modelFieldMessages.batchId
    },
    type: {
      key: 'type',
      type: 'string',
      title: modelFieldMessages.type
    },
    reserve: {
      key: 'reserve',
      type: 'string',
      title: modelFieldMessages.reserve
    },
    id: {
      key: 'id',
      type: 'string',
      title: modelFieldMessages.id
    },
    totalLamellas: {
      key: 'totalLamellas',
      type: 'string',
      title: modelFieldMessages.totalLamellas
    },
    totalSequence: {
      key: 'totalSequence',
      type: 'string',
      title: modelFieldMessages.totalLamellas
    },
    insId: {
      key: 'insId',
      type: 'string',
      title: modelFieldMessages.id
    },
    materialSelect: {
      key: 'materialSelect',
      type: 'select',
      format: {
        item: 'cltIncomingPackages',
        identifier: 'materialCode',
        wsAction: 'select-material-code',
        validationFunction: function (item, selection) {
          // console.log('checking for item ', item, selection);
          let visualQualityAllowance = false;
          // structural class validation
          let quality = selection.quality;
          if (quality == '') {
            // quality dobimo iz material code
            if (selection.materialCode[0] === 'F') {
              quality = selection.materialCode[8];
            } else {
              quality = selection.materialCode[11];
            }
          }
          const getQualityInt = (qualityChar) => {
            if (qualityChar == 'N') return 1;
            if (qualityChar == 'V') return 2;
            if (qualityChar == 'S') return 3;
            else return 4;
          };

          if (getQualityInt(quality) < getQualityInt(item.quality[0])) visualQualityAllowance = true;
          // console.log('quality', quality, item.quality[0]);

          // strength validation
          let structuralClassAllowance = false;
          if (selection.strengthString == '') {
            // dobimo iz material code
            if (selection.materialCode[0] === 'F') {
              selection.strengthString = selection.materialCode.slice(4, 7);
            } else {
              selection.strengthString = selection.materialCode.slice(0, 3);
            }
          }

          if (selection.strengthString !== item.strengthString) {
            structuralClassAllowance = true;
          }

          // width & thickness validation
          let dimensionValidation = false;
          if (selection.lamellaThickness != item.lamellaThickness || selection.lamellaWidth != item.lamellaWidth) {
            dimensionValidation = true;
          }
          // console.log('structural', selection.strengthString, item.strengthString);
          // console.log('structuralClassAllowance', structuralClassAllowance, 'visualQualityAllowance', visualQualityAllowance, 'together', structuralClassAllowance || visualQualityAllowance);
          if (item.canUseAnyPackage) {
            // overwrite, da se lahko izbere karkoli
            return false;
          } else return structuralClassAllowance || visualQualityAllowance || dimensionValidation;
        }
      },
      title: modelFieldMessages.materialSelect
    },
    // arborealDynamicCheckBox: {
    //   key: 'check',
    //   format: {
    //     type: 'dynamicCheckbox',
    //     enableFunction: function (item, checkedItems) {
    //       console.log('item', item, 'checkedItems', checkedItems);
    //       return true;
    //     }
    //   }
    // },
    projectNumber: {
      key: 'projectNumber',
      type: 'string',
      title: modelFieldMessages.projectNumber
    },
    jobCode: {
      key: 'jobCode',
      type: 'string',
      title: modelFieldMessages.jobCode
    },
    jobCodeSodra: {
      key: 'jobCode',
      type: 'string',
      format: {
        bold: true,
        size: '10px'
      },
      title: modelFieldMessages.jobCode
    },
    masterpanel: {
      key: 'masterpanel',
      type: 'string',
      title: modelFieldMessages.masterpanel
    },
    panelWidth: {
      key: 'panelWidth',
      type: 'string',
      title: modelFieldMessages.panelWidth
    },
    woodType: {
      key: 'woodType',
      type: 'string',
      title: modelFieldMessages.woodType
    },
    quality: {
      key: 'quality',
      type: 'string',
      title: modelFieldMessages.quality
    },
    beamWidth: {
      key: 'beamWidth',
      type: 'string',
      title: modelFieldMessages.beamWidth
    },
    position: {
      key: 'position',
      type: 'string',
      title: modelFieldMessages.position
    },
    finalLength: {
      key: 'finalLength',
      type: 'string',
      title: modelFieldMessages.finalLength
    },
    originalLamellaLength: {
      key: 'originalLamellaLength',
      type: 'string',
      title: modelFieldMessages.originalLamellaLength
    },
    numLamellas: {
      key: 'numLamellas',
      type: 'string',
      title: modelFieldMessages.numLamellas
    },
    numberOfBeams: {
      key: 'numberOfBeams',
      type: 'string',
      title: modelFieldMessages.numberOfBeams
    },
    beamLength: {
      key: 'beamLength',
      type: 'string',
      title: modelFieldMessages.beamLength
    },
    beamPartLengths: {
      key: 'beamPartLengths',
      type: 'string',
      title: modelFieldMessages.beamPartLengths
    },
    insNumLamellas: {
      key: 'insNumLamellas',
      type: 'string',
      title: modelFieldMessages.numLamellas
    },
    mosserNumLamellas: {
      key: 'numLamellas',
      type: 'string',
      title: modelFieldMessages.numLamellas,
      format: {
        type: 'eval_function',
        str: "{numLamellas}+ ' / ' +{actualPlates}",
        keys: {
          numLamellas: '{numLamellas}',
          actualPlates: '{actualPlates}'
        },
        evalFinalString: true
      }
    },
    numLamellasWithExtra: {
      key: 'numLamellasWithExtra',
      format: {
        type: 'eval_function',
        str: '{numLamellas} (+{extraLamella}) ',
        keys: {
          numLamellas: '{numLamellas}',
          extraLamella: '{extraLamella}'
        }
      }
    },
    numLamellasWithExtraSchneider: {
      key: 'numLamellasWithExtraSchneider',
      format: {
        type: 'eval_function',
        str: '{numLamellas} -{subtractedLamellasDouble} (+{extraLamella}) ',
        keys: {
          numLamellas: '{numLamellas}',
          extraLamella: '{extraLamella}',
          subtractedLamellasDouble: '{subtractedLamellasDouble}'
        }
      }
    },
    zincDoneNumLamellas: {
      key: 'zincDoneNumLamellas',
      type: 'string',
      title: modelFieldMessages.zincDoneNumLamellas
    },
    planerDoneNumLamellas: {
      key: 'planerDoneNumLamellas',
      type: 'string',
      title: modelFieldMessages.planerDoneNumLamellas
    },
    insZincDoneNumLamellas: {
      key: 'insZincDoneNumLamellas',
      type: 'string',
      title: modelFieldMessages.zincDoneNumLamellas
    },
    insRawZincDoneNumLamellas: {
      key: 'insRawNumLamellas',
      type: 'string',
      title: modelFieldMessages.insRawZincDoneNumLamellas
    },
    calculatedLamellas: {
      key: 'calculatedLamellas',
      type: 'string',
      title: modelFieldMessages.calculatedLamellas
    },
    zPressDoneNumLamellas: {
      key: 'zPressDoneNumLamellas',
      type: 'string',
      title: modelFieldMessages.zPressDoneNumLamellas
    },
    startSequence: {
      key: 'startSequence',
      type: 'string',
      title: modelFieldMessages.startSequence
    },
    endSequence: {
      key: 'endSequence',
      type: 'string',
      title: modelFieldMessages.endSequence
    },
    optimalGroupSize: {
      key: 'optimalGroupSize',
      type: 'string',
      title: modelFieldMessages.optimalGroupSize
    },
    numberOfPlates: {
      key: 'numberOfPlates',
      type: 'string',
      title: modelFieldMessages.numberOfPlates
    },
    calculatedLength: {
      key: 'calculatedLength',
      type: 'string',
      title: modelFieldMessages.calculatedLength
    },
    calculatedNumLamellas: {
      key: 'calculatedNumLamellas',
      type: 'string',
      title: modelFieldMessages.calculatedNumLamellas
    },
    zPressDonePlates: {
      key: 'zPressDonePlates',
      type: 'string',
      title: modelFieldMessages.zPressDonePlates
    },
    totalZPressPlatesDone: {
      key: 'totalZPressPlatesDone',
      type: 'string',
      title: modelFieldMessages.totalZPressPlatesDone
    },
    outerLayer: {
      key: 'outerLayer',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.outerLayer
    },
    orders: {
      key: 'wsOrderId',
      type: 'foreignkeyarray',
      title: modelFieldMessages.orders
    },
    ordersClient: {
      key: 'wsClientOrderId',
      type: 'foreignkeyarray',
      title: modelFieldMessages.ordersClient
    },
    producedOnZinc: {
      key: 'finishedProducingOnZinc',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.producedOnZinc
    },
    finishedProducingInsZinc: {
      key: 'finishedProducingInsZinc',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.finishedProducingInsZinc
    },
    producingOnZinc: {
      key: 'producingOnZinc',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.producingOnZinc
    },
    producingOnISoZinc: {
      key: 'producingOnISoZinc',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.producingOnISoZinc
    },
    finishedProducingOnZPress: {
      key: 'finishedProducingOnZPress',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.finishedProducingOnZPress
    },
    producingOnZPress: {
      key: 'producingOnZPress',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.producingOnZPress
    },
    seenOnDryingStorage: {
      key: 'seenOnDryingStorage',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.seenOnDryingStorage
    },
    currentlyLoadingMaterialFor: {
      key: 'currentlyLoadingMaterialFor',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.producingOnZinc
    },
    fullyAssignedOnDrying: {
      key: 'fullyAssignedOnDrying',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.fullyAssignedOnDrying
    },
    repairOrder: {
      key: 'repairOrder',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.repairOrder
    },
    strengthGrade: {
      key: 'strengthGrade',
      title: modelFieldMessages.grade
    },
    FJmachine: {
      key: 'FJmachine',
      title: modelFieldMessages.machine
    },
    cutDateString: {
      key: 'cutDateString',
      title: modelFieldMessages.cutDateString
    },
    endOffset: {
      key: 'endOffset',
      title: modelFieldMessages.endOffset
    },
    startOffset: {
      key: 'startOffset',
      title: modelFieldMessages.startOffset
    },
    absoluteEndOffset: {
      key: 'absoluteEndOffsetDouble',
      title: modelFieldMessages.endOffset
    },
    absoluteStartOffset: {
      key: 'absoluteStartOffsetDouble',
      title: modelFieldMessages.startOffset
    },
    skipIsoDimension: {
      key: 'skipIsoDimension',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.skipIsoDimension
    },
    qLamellaNum: {
      key: 'qLamellaNum',
      title: modelFieldMessages.qLamellaNum
    },
    qInsLamellaNum: {
      key: 'qInsLamellaNum',
      title: modelFieldMessages.qInsLamellaNum
    },
    evalTest: {
      title: modelFieldMessages.rawDimensions,
      format: {
        type: 'eval_function',
        str: "{isKVH} == true ? ({lamellaWidth} + 'x' + {numLamellas}):{numLamellas}",
        keys: {
          isKVH: '{isKVH}',
          lamellaWidth: '{lamellaWidth}',
          lamellaLength: '{lamellaLength}',
          numLamellas: '{numLamellas}'
        },
        evalFinalString: true
      }
    },
    schneiderId: {
      title: modelFieldMessages.id,
      format: {
        type: 'eval_function',
        str: "{extraPlateOrder} == true ? ({id} + ' (' + {layerId} + ')'):{id}",
        keys: {
          extraPlateOrder: '{extraPlateOrder}',
          id: '{id}',
          layerId: '{layerId}'
        },
        evalFinalString: true
      }
    },
    lamellasInPackage: {
      title: modelFieldMessages.lamellasInOnePackage,
      format: {
        type: 'eval_function',
        str: "{currentPackageLamellas}+ '/' +{lamellasInOnePackage}",
        keys: {
          currentPackageLamellas: '{currentPackageLamellas}',
          lamellasInOnePackage: '{lamellasInOnePackage}'
        },
        evalFinalString: true
      }
    }
  };

  static actions = {
    revertOrder: {
      key: 'revertOrder',
      format: {
        type: 'optionalItem',
        modalTo: '/perspective/joineddimensions/revertposition',
        icon: 'revert',
        compareField: 'finishedProducingOnZinc',
        style: { fontSize: '27px', color: 'black' }
      },
      title: modelFieldMessages.revertOrder
    },

    editJD: {
      adminRequired: true,
      key: 'editJD',
      format: {
        type: 'action',
        modalTo: '/perspective/joineddimensions/editjdmodal',
        icon: 'split',
        style: { fontSize: '27px', color: 'black' }
      },
      title: modelFieldMessages.editJD
    },
    editJDArolla: {
      adminRequired: true,
      key: 'editJD',
      format: {
        type: 'action',
        modalTo: '/perspective/joineddimensions/editjdmodal',
        icon: 'split',
        style: { fontSize: '27px', color: 'black', marginTop: '0px' }
      },
      title: modelFieldMessages.editJD
    },
    moveUp: {
      key: 'move-up-konti',
      format: {
        type: 'action',
        modalTo: '/jdqueue',
        icon: 'moveUp',
        style: { fontSize: '33px', color: 'black' }
      },
      compare: {
        type: 'eval_function_bool',
        str: '{extraLamellaOrder} == true || {isKVH} == true',
        keys: {
          extraLamellaOrder: '{extraLamellaOrder}',
          isKVH: '{isKVH}'
        }
      },
      title: modelFieldMessages.moveUp
    },
    moveDown: {
      key: 'move-down-konti',
      format: {
        type: 'action',
        modalTo: '/jdqueue',
        icon: 'moveDown',
        style: { fontSize: '33px', color: 'black' }
      },
      compare: {
        type: 'eval_function_bool',
        str: '{extraLamellaOrder} == true || {isKVH} == true',
        keys: {
          extraLamellaOrder: '{extraLamellaOrder}',
          isKVH: '{isKVH}'
        }
      },
      title: modelFieldMessages.moveDown
    },
    moveUpNoCompare: {
      adminRequired: true,
      key: 'move-up-konti',
      format: {
        type: 'action',
        modalTo: '/jdqueue',
        icon: 'moveUp',
        style: { fontSize: '33px', color: 'black' }
      },
      title: modelFieldMessages.moveUp
    },
    moveDownNoCompare: {
      adminRequired: true,
      key: 'move-down-konti',
      format: {
        type: 'action',
        modalTo: '/jdqueue',
        icon: 'moveDown',
        style: { fontSize: '33px', color: 'black' }
      },
      title: modelFieldMessages.moveDown
    },
    sendToZink: {
      key: 'send-to-zink',
      format: {
        type: 'action',
        modalTo: '/jdqueue',
        icon: 'backHand',
        style: { fontSize: '33px', color: 'black' }
      },
      title: modelFieldMessages.sendToZink
    },
    moveGroup: {
      key: 'switch-group',
      format: {
        type: 'action',
        modalTo: '/jdqueue',
        icon: 'start',
        style: { fontSize: '33px', color: 'black' }
      },
      title: modelFieldMessages.moveDown
    },
    resendCraneData: {
      key: 'resend-crane-data',
      format: {
        type: 'action',
        modalTo: '/jdqueue',
        icon: 'revert',
        style: { fontSize: '33px', color: 'black' }
      },
      title: modelFieldMessages.moveDown
    },
    moveTop: {
      key: 'move-top-konti',
      format: {
        type: 'action',
        modalTo: '/jdqueue',
        icon: 'moveTop',
        style: { fontSize: '33px', color: 'black' }
      },
      compare: {
        type: 'eval_function_bool',
        str: '{extraLamellaOrder} == true || {isKVH} == true',
        keys: {
          extraLamellaOrder: '{extraLamellaOrder}',
          isKVH: '{isKVH}'
        }
      },
      title: modelFieldMessages.moveTop
    },
    startJD: {
      adminRequired: true,
      key: 'startJD',
      format: {
        type: 'action',
        modalTo: '/jdqueue',
        icon: 'start',
        style: { fontSize: '33px', color: 'black' }
      },
      compare: {
        type: 'eval_function_bool',
        str: '{emptyFromVacuumToZink} == true && {finishedProducingOnZinc} == false',
        keys: {
          emptyFromVacuumToZink: '{emptyFromVacuumToZink}',
          finishedProducingOnZinc: '{finishedProducingOnZinc}'
        }
      },
      title: modelFieldMessages.startJD
    },
    startJDbruno: {
      adminRequired: true,
      key: 'startJD',
      format: {
        // type: 'optionalItemToggle',
        type: 'optionalItemToggle',
        modalTo: '/jdqueue',
        icon: 'start',
        style: { fontSize: '33px', color: 'black' },
        trueIcon: 'start',
        falseIcon: 'stop',
        compareField: 'producingOnZinc'
      },
      compare: {
        type: 'eval_function_bool',
        str: '{finishedProducingOnZinc} == false',
        keys: {
          finishedProducingOnZinc: '{finishedProducingOnZinc}'
        }
      },
      title: modelFieldMessages.startOrder
    },
    startJDsolis: {
      adminRequired: true,
      key: 'startJD',
      format: {
        // type: 'optionalItemToggle',
        type: 'optionalItemToggle',
        modalTo: '/jdqueue',
        icon: 'start',
        style: { fontSize: '33px', color: 'black' },
        trueIcon: 'start',
        falseIcon: 'stop',
        compareField: 'producingOnZinc'
      },
      compare: {
        type: 'eval_function_bool',
        str: '{finishedProducingOnZinc} == false',
        keys: {
          finishedProducingOnZinc: '{finishedProducingOnZinc}'
        }
      },
      title: modelFieldMessages.startJD
    },
    startJDArolla: {
      adminRequired: false,
      key: 'startJD',
      format: {
        type: 'action',
        modalTo: '/jdqueue',
        icon: 'start',
        style: {
          fontSize: '33px',
          color: 'black',
          marginTop: '0px',
          position: 'relative',
          right: '13px'
        }
      },
      compare: {
        type: 'eval_function_bool',
        str: '{finishedProducingOnZPress} == false',
        keys: {
          finishedProducingOnZPress: '{finishedProducingOnZPress}'
        }
      },
      title: modelFieldMessages.startJD
    },
    startJDArboreal: {
      adminRequired: false,
      key: 'startJD',
      format: {
        type: 'action',
        modalTo: '/jdqueue',
        icon: 'start',
        style: {
          fontSize: '33px',
          color: 'black',
          marginTop: '0px',
          position: 'relative',
          right: '13px'
        }
      },
      title: modelFieldMessages.startJD
    },
    removeRepairOrder: {
      key: 'delete-repair-order',
      format: {
        compareField: 'extraLamellaOrder',
        type: 'action',
        modalTo: '/jdqueue',
        icon: 'delete',
        style: { fontSize: '33px', color: 'black' }
      },
      compare: {
        type: 'eval_function_bool',
        str: '{extraLamellaOrder} == true',
        keys: {
          extraLamellaOrder: '{extraLamellaOrder}'
        }
      },
      title: modelFieldMessages.removeRepairOrder
    },
    deleteConfirmRepairOrder: {
      key: 'delete',
      type: 'delete',
      style: { fontSize: '33px', color: 'black' },
      dispatch: 'deleteRepair',
      compare: {
        type: 'eval_function_bool',
        str: '{extraLamellaOrder} == true',
        keys: {
          extraLamellaOrder: '{extraLamellaOrder}'
        }
      },
      modal: {
        modal: true,
        buttonText: 'deleteRepair',
        key: 'delete',
        action: 'delete',
        actionData: {
          target: 'control',
          source: 'ordersqueue',
          identifier: 'delete-repair-order',
          items: 'selected',
          callType: 'POST'
        }
      },
      title: modelFieldMessages.delete
    },
    subtractFromJD: {
      key: 'action',
      type: 'controlAction',
      format: {
        compareField: 'finishedProducingOnZinc',
        icon: 'minus',
        style: { fontSize: '27px', color: 'black' }
      },
      modal: {
        modal: true,
        buttonText: 'subtractFromID',
        key: 'subtractFromID',
        action: 'subtractFromID',
        icon: 'minus',
        actionData: {
          target: 'control',
          source: 'ordersqueue',
          identifier: 'subtract-from-id',
          items: 'selected',
          callType: 'POST'
        }
      },
      title: modelFieldMessages.subtractFromID
    },
    selectGroup: {
      type: 'action',
      key: 'customModalAction',
      format: {
        type: 'action',
        icon: 'GroupWork',
        style: { fontSize: '33px', color: 'black' }
      },
      compare: {
        type: 'eval_function_bool',
        str: '{extraLamellaOrder} == true',
        keys: {
          extraLamellaOrder: '{extraLamellaOrder}'
        }
      },
      itemsKey: 'availableGroups',
      viewFunction: (groups, setLocalState, localState) => {
        //define view for custom modal. Must pass in setters and state that is managed in CustomActionModal
        const itemFilters = localState.item; // Assuming localState.item has all required filters
        const filteredGroups = groups.filter((group) => {
          // Check each required property. Adjust these checks as needed based on your actual data structure.
          return (
            (!itemFilters?.lamellaThickness || group.lamellaThickness === itemFilters.lamellaThickness) &&
            (!itemFilters?.lamellaWidth || group.lamellaWidth === itemFilters.lamellaWidth) &&
            (!itemFilters?.splitWood || group.splitWood === itemFilters.splitWood) &&
            (!itemFilters?.quality || group.quality === itemFilters.quality) &&
            (!itemFilters?.FJmachine || group.FJmachine === itemFilters.FJmachine) &&
            (!itemFilters?.group || group.id !== itemFilters.group.id)
          );
        });

        const handleCheckboxChange = (event) => {
          setLocalState((prevState) => ({ ...prevState, urgent: event.target.checked }));
        };

        return (
          <>
            <FormControl fullWidth>
              <InputLabel id="group-select-label">Select Group</InputLabel>
              <Select
                labelId="group-select-label"
                id="group-select"
                value={localState.group || ''}
                label="Select Group"
                disabled={filteredGroups.length < 1}
                onChange={(e) => setLocalState((prevState) => ({ ...prevState, group: e.target.value }))}>
                {filteredGroups.map((group) => (
                  <MenuItem key={group.id} value={group}>
                    {group.id}
                  </MenuItem>
                ))}
              </Select>
              {filteredGroups.length < 1 && (
                <FormHelperText>
                  <FormattedMessage {...globalMessages.noGroupAvailable} />
                </FormHelperText>
              )}
            </FormControl>
            <div style={{ display: 'flex', gap: 1, alignItems: 'center', justifyContent: 'center' }}>
              <Checkbox checked={localState.urgent || false} onChange={handleCheckboxChange} />
              <Typography variant="body">Urgent</Typography>
            </div>
            {localState.item && (
              <Box mt={2} mb={2} display="flex" flexDirection="column">
                <Typography variant="h5">
                  <FormattedMessage {...globalMessages.itemDetails} />
                </Typography>
                <Typography variant="body">
                  <FormattedMessage {...globalMessages.lamellaThickness} />: {localState.item.lamellaThickness}
                </Typography>
                <Typography variant="body">
                  <FormattedMessage {...globalMessages.lamellaWidth} />: {localState.item.lamellaWidth}
                </Typography>
                <Typography variant="body">
                  <FormattedMessage {...globalMessages.splitWood} />: {localState.item.splitWood ? 'True' : 'False'}
                </Typography>
                <Typography variant="body">
                  <FormattedMessage {...globalMessages.quality} />: {localState.item.quality}
                </Typography>
                <Typography variant="body">
                  <FormattedMessage {...globalMessages.FJMachine} />: {localState.item.FJmachine}
                </Typography>
                <Typography variant="body">
                  <FormattedMessage {...globalMessages.currentGroup} />: {localState.item.group.id}
                </Typography>
              </Box>
            )}
          </>
        );
      },
      onSave: (currentItemState, dispatch, closeFn) => {
        //what happens with the selected item/values when you click the save button
        dispatch(sendToWs(currentItemState, 'set-group'));
        closeFn();
      },
      title: modelFieldMessages.selectGroup
    },
    changeCarpetDimension: {
      type: 'action',
      key: 'customModalAction',
      title: globalMessages.selectWidth,
      format: {
        type: 'action',
        icon: 'Ruler',
        style: { fontSize: '33px', color: 'black' }
      },
      compare: {
        type: 'eval_function_bool',
        str: '{producingOnZinc} == true',
        keys: {
          producingOnZinc: '{producingOnZinc}'
        }
      },
      itemsKey: 'settingsList',
      viewFunction: (entries, setLocalState, localState) => {
        const itemFilters = localState.item;
        let alreadyIn = {};
        let filteredGroups = [];
        entries.forEach((item) => {
          if (alreadyIn[item?.rawWidth] == undefined) {
            filteredGroups.push(item);
            alreadyIn[item?.rawWidth] = true;
          }
        });

        return (
          <>
            <FormControl fullWidth>
              <InputLabel id="group-select-label">
                <FormattedMessage {...globalMessages.selectWidth} />
              </InputLabel>
              <Select
                labelId="group-select-label"
                id="group-select"
                value={localState.setting || ''}
                label={<FormattedMessage {...globalMessages.selectWidth} />}
                disabled={filteredGroups.length < 1}
                onChange={(e) => setLocalState((prevState) => ({ ...prevState, setting: e.target.value }))}>
                {filteredGroups.map((group) => (
                  <MenuItem key={group?.rawWidth} value={group}>
                    {group?.rawWidth}
                  </MenuItem>
                ))}
              </Select>
              {filteredGroups.length < 1 && (
                <FormHelperText>
                  <FormattedMessage {...globalMessages.noGroupAvailable} />
                </FormHelperText>
              )}
            </FormControl>
            {localState.item && (
              <Box mt={2} mb={2} display="flex" flexDirection="column">
                <Typography variant="h5">
                  <FormattedMessage {...globalMessages.itemDetails} />
                </Typography>
                <Typography variant="body">
                  <FormattedMessage {...globalMessages.lamellaThickness} />: {localState.item.lamellaThickness}
                </Typography>
                <Typography variant="body">
                  <FormattedMessage {...globalMessages.lamellaWidth} />: {localState.item.lamellaWidth}
                </Typography>
                <Typography variant="body">
                  <FormattedMessage {...globalMessages.splitWood} />: {localState.item.splitWood ? 'True' : 'False'}
                </Typography>
                <Typography variant="body">
                  <FormattedMessage {...globalMessages.quality} />: {localState.item.quality}
                </Typography>
                <Typography variant="body">
                  <FormattedMessage {...globalMessages.FJMachine} />: {localState.item.FJmachine}
                </Typography>
                <Typography variant="body">
                  <FormattedMessage {...globalMessages.currentGroup} />: {localState.item.group.id}
                </Typography>
              </Box>
            )}
          </>
        );
      },
      onSave: (currentItemState, dispatch, closeFn) => {
        //what happens with the selected item/values when you click the save button
        dispatch(sendToWs(currentItemState, 'change-width'));
        closeFn();
      }
    },
    brokenLayer: {
      key: 'action',
      type: 'controlAction',
      format: {
        type: 'action',
        icon: 'BrokenLayer',
        style: { fontSize: '33px', color: 'black' }
      },
      compare: {
        type: 'eval_function_bool',
        str: '{layerCutOnSaw} == true',
        keys: {
          layerCutOnSaw: '{layerCutOnSaw}'
        }
      },
      modal: {
        modal: true,
        buttonText: 'brokenLayer',
        key: 'brokenLayer',
        action: 'brokenLayer',
        actionData: {
          target: 'control',
          source: 'ordersqueue',
          identifier: 'broken-layer-action',
          items: 'selected',
          callType: 'POST'
        }
      },
      title: globalMessages.brokenLayer
    },
    forceEmpty: {
      key: 'action',
      type: 'controlAction',
      format: {
        type: 'action',
        icon: 'BrokenLayer',
        style: { fontSize: '33px', color: 'black' }
      },
      modal: {
        modal: true,
        buttonText: 'forceEmpty',
        key: 'forceEmpty',
        action: 'forceEmpty',
        actionData: {
          target: 'control',
          source: 'ordersqueue',
          identifier: 'force-empty-action',
          items: 'selected',
          callType: 'POST'
        }
      },
      title: globalMessages.forceEmpty
    },
    setCutAtSaw: {
      key: 'action',
      type: 'controlAction',
      format: {
        type: 'action',
        icon: 'CutAtSaw',
        style: { fontSize: '33px', color: 'black' }
      },
      compare: {
        type: 'eval_function_bool',
        str: '{layerCutOnSaw} == false',
        keys: {
          layerCutOnSaw: '{layerCutOnSaw}'
        }
      },
      modal: {
        modal: true,
        buttonText: 'cutAtSaw',
        key: 'cutAtSaw',
        action: 'cutAtSaw',
        actionData: {
          target: 'control',
          source: 'ordersqueue',
          identifier: 'set-cut-at-saw',
          items: 'selected',
          callType: 'POST'
        }
      },
      title: globalMessages.cutAtSaw
    },
    seeEverywhere: {
      key: 'action',
      type: 'setSeeEverywhere',
      format: {
        type: 'action',
        icon: 'SeeEverywhere',
        style: { fontSize: '33px' }
      },
      modal: {
        modal: true,
        buttonText: 'seeEverywhere',
        key: 'seeEverywhere',
        action: 'seeEverywhere',
        actionData: {
          target: 'control',
          source: 'ordersqueue',
          identifier: 'set-see-everywhere',
          items: 'selected',
          callType: 'POST'
        }
      },
      title: globalMessages.seeEverywhere
    }
  };
}

export default JoinedDimensions;
