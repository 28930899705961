const schneider_edit_settings = [
  {
    'set-infeed': {
      title: 'Set Infeed',
      settings: [
        { rawSortingLoading: { type: 'status', identifier: 'rawSortingLoading', value: null } },
        {
          allowanceRawInfeed: { type: 'boolStatus', identifier: 'allowanceRawInfeed', value: false }
        },
        {
          numberOfBoardsBeforePlaner: {
            type: 'status',
            identifier: 'numberOfBoardsBeforePlaner',
            value: 0
          }
        },
        {
          allowanceMultiplan: { type: 'boolStatus', identifier: 'allowanceMultiplan', value: false }
        }
      ]
    }
  },
  {
    'set-superplan': {
      title: 'Set Superplan',
      settings: [
        {
          sortingInfeedWidthOffset: {
            type: 'status',
            identifier: 'sortingInfeedWidthOffset',
            value: 0
          }
        },
        {
          sortingInfeedThicknessOffset: {
            type: 'status',
            identifier: 'sortingInfeedThicknessOffset',
            value: 0
          }
        },
        {
          sortingInfeedPlannedWidthOffset: {
            type: 'status',
            identifier: 'sortingInfeedPlannedWidthOffset',
            value: 0
          }
        },
        {
          sortingInfeedPlannedThicknessOffset: {
            type: 'status',
            identifier: 'sortingInfeedPlannedThicknessOffset',
            value: 0
          }
        },
        { sortingInfeedWidth: { type: 'status', identifier: 'sortingInfeedWidth', value: 0 } },
        {
          sortingInfeedThickness: { type: 'status', identifier: 'sortingInfeedThickness', value: 0 }
        },
        {
          sortingInfeedPlannedWidth: {
            type: 'status',
            identifier: 'sortingInfeedPlannedWidth',
            value: 0
          }
        },
        {
          sortingInfeedPlannedThicknessOffset: {
            type: 'status',
            identifier: 'sortingInfeedPlannedThicknessOffset',
            value: 0
          }
        }
      ]
    }
  },
  {
    'set-drying': {
      title: 'Set Drying',
      settings: [
        { manualFilling: { type: 'boolStatus', identifier: 'manualFilling', value: false } },
        { manualFillingEtage: { type: 'status', identifier: 'manualFillingEtage', value: 0 } },
        { manualEmptying: { type: 'boolStatus', identifier: 'manualEmptying', value: false } },
        { manualEmptyingID: { type: 'status', identifier: 'manualEmptyingID', value: 0 } },
        { dryingTime: { type: 'status', identifier: 'dryingTime', value: 0 } }
      ]
    }
  },
  {
    'set-zpress': {
      title: 'Set Zpress',
      settings: [
        {
          whiteGlue: {
            type: 'boolStatus',
            identifier: 'whiteGlue',
            value: false
          }
        }
      ]
    }
  },
  {
    'set-drying': {
      title: 'Set Drying',
      settings: [
        { manualFilling: { type: 'boolStatus', identifier: 'manualFilling', value: false } },
        { manualFillingEtage: { type: 'status', identifier: 'manualFillingEtage', value: 0 } },
        { manualEmptying: { type: 'boolStatus', identifier: 'manualEmptying', value: false } },
        { manualEmptyingID: { type: 'status', identifier: 'manualEmptyingID', value: 0 } },
        { dryingTime: { type: 'status', identifier: 'dryingTime', value: 0 } }
      ]
    }
  },
  {
    'zink1-set': {
      title: 'Set Zink1',
      settings: [
        {
          woodBufferForDimension: {
            type: 'boolStatus',
            identifier: 'zink1-woodBufferForDimension',
            value: false
          }
        },
        {
          negativeWoodBufferForBetterDimensionNext: {
            type: 'status',
            identifier: 'zink1-negativeWoodBufferForBetterDimensionNext',
            value: 0
          }
        },
        {
          percentageOfWoodBeforeGoldenEye: {
            type: 'status',
            identifier: 'zink1-percentageOfWoodBeforeGoldenEye',
            value: 0
          }
        },
        { changeWidthFrom: { type: 'status', identifier: 'changeWidthFrom', value: 0 } },
        { changeWidthTo: { type: 'value', value: 0 } },
        { changeThicknessFrom: { type: 'status', identifier: 'changeThicknessFrom', value: 0 } },
        { changeThicknessTo: { type: 'value', value: 0 } },
        { changeBoardDimensions: { type: 'bool', value: false } },
        { makeExtraLamellasId: { type: 'value', value: 0 } },
        { makeExtraLamellasNum: { type: 'value', value: 0 } },
        { makeExtraLamellas: { type: 'bool', value: false } },
        { changePlaneThicknessFrom: { type: 'value', value: 0 } },
        { changePlaneThicknessTo: { type: 'value', value: 0 } },
        { changePlaneThickness: { type: 'bool', value: false } },
        { stickerTest: { type: 'bool', value: false } }
      ]
    }
  },
  {
    'zink2-set': {
      title: 'Set Zink2',
      settings: [
        {
          woodBufferForDimension: {
            type: 'boolStatus',
            identifier: 'zink2-woodBufferForDimension',
            value: false
          }
        },
        {
          negativeWoodBufferForBetterDimensionNext: {
            type: 'status',
            identifier: 'zink2-negativeWoodBufferForBetterDimensionNext',
            value: 0
          }
        },
        {
          percentageOfWoodBeforeGoldenEye: {
            type: 'status',
            identifier: 'zink2-percentageOfWoodBeforeGoldenEye',
            value: 0
          }
        },
        { changeWidthFrom: { type: 'status', identifier: 'changeWidthFrom', value: 0 } },
        { changeWidthTo: { type: 'value', value: 0 } },
        { changeThicknessFrom: { type: 'status', identifier: 'changeThicknessFrom', value: 0 } },
        { changeThicknessTo: { type: 'value', value: 0 } },
        { changeBoardDimensions: { type: 'bool', value: false } },
        { makeExtraLamellasId: { type: 'value', value: 0 } },
        { makeExtraLamellasNum: { type: 'value', value: 0 } },
        { makeExtraLamellas: { type: 'bool', value: false } },
        { changePlaneThicknessFrom: { type: 'value', value: 0 } },
        { changePlaneThicknessTo: { type: 'value', value: 0 } },
        { changePlaneThickness: { type: 'bool', value: false } },
        { stickerTest: { type: 'bool', value: false } }
      ]
    }
  },
  {
    'set-no-group': {
      title: 'Set No Group',
      settings: [
        { noGroupMode: { type: 'boolStatus', identifier: 'noGroupMode', value: false } },
        {
          noGroupModeWidthTakeaway: {
            type: 'status',
            identifier: 'noGroupModeWidthTakeaway',
            value: 0
          }
        },
        {
          noGroupModeWidthTakeaway: {
            type: 'status',
            identifier: 'noGroupModeWidthTakeaway',
            value: 0
          }
        },
        { noGroupModeCOversize: { type: 'status', identifier: 'noGroupModeCOversize', value: 0 } },
        { noGroupModeLOversize: { type: 'status', identifier: 'noGroupModeLOversize', value: 0 } },
        { kvhOversize: { type: 'status', identifier: 'kvhOversize', value: 0 } },
        { xcutOversize: { type: 'status', identifier: 'xcutOversize', value: 0 } }
      ]
    }
  },
  {
    'set-editjd': {
      settings: [],
      cherryPick: [
        'id',
        'changeId',
        'lamellaThickness',
        'lamellaWidth',
        'lamellaLength',
        'lamellaPlannedThickness',
        'lamellaPlannedWidth',
        'quality',
        'numLamellas',
        'zincDoneNumLamellas',
        'producingOnZinc',
        'zPressDoneNumLamellas',
        'finishedProducingOnZinc',
        'FJmachine',
        'orientation',
        'rotation',
        'producingOnZPress',
        'finishedProducingOnZPress',
        'acknowledgedOnZinc',
        'orderCharge',
        'noGroupMode',
        'masterpanel',
        'noGroupUsed',
        'layerCutOnSaw',
        'splitWood',
        'currentOffset',
        'craneCommandSent',
        'craneCommandOk',
        'craneCommandError',
        'sawOffset',
        'extraLamella',
        'correctionAdded',
        'errorLength',
        'extraLamellaOrder',
        'extraPlateOrder',
        'wasteCycle',
        'canFinishZPressOrder',
        'panelWidth'
      ]
    }
  },
  {
    'set-editControlOrder': {
      settings: [],
      cherryPick: [
        'lamellaThickness',
        'rawThickness',
        'rawWidth',
        'woodType',
        'quality',
        'batchCutOnLength',
        'splitWood',
        'syncedWithErp',
        'wasteCycle',
        'assignedDimensions',
        'avoidCheck',
        'stackStartedSent',
        'articleCode'
      ]
    }
  }
];

export default schneider_edit_settings;
